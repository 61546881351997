import React from "react";

import { Text } from "@react-three/drei/Text";

const Monogram = (props) => {
  const { iniziali } = props;

  let fontFam = "/fonts/aria_8984ea7fcbebdcfe77fbbc1b86e7cfe6.woff";

  switch (iniziali.stile) {
    case "block":
      fontFam = "/fonts/aria_8984ea7fcbebdcfe77fbbc1b86e7cfe6.woff";

      break;
    case "script":
      fontFam = "/fonts/comm_e965995ca080e47db1c4aff1a08fa11e.woff"; //"https://db.onlinewebfonts.com/t/e965995ca080e47db1c4aff1a08fa11e.woff";

      break;
    case "serif":
      fontFam =
        "https://fonts.gstatic.com/s/philosopher/v9/vEFV2_5QCwIS4_Dhez5jcWBuT0s.woff";

      break;
    default:
      fontFam = "/fonts/aria_8984ea7fcbebdcfe77fbbc1b86e7cfe6.woff";
  }

  let fontCol = "#f5f5f5";
  switch (iniziali.colore) {
    case 0:
      fontCol = "#f5f5f5";
      break;
    case 1:
      fontCol = "#0A0AAC";
      break;
    case 2:
      fontCol = "#082C4F";
      break;
    case 3:
      fontCol = "#BE0C0C";
      break;
    case 4:
      fontCol = "#121212";
      break;
    case 5:
      fontCol = "#1F1F1F";
      break;
    case 6:
      fontCol = "#7A2D0D";
      break;
    case 7:
      fontCol = "#074D07";
      break;
    case 8:
      fontCol = "#8D294E";
      break;
    default:
      fontCol = "#f5f5f5";
  }

  let fontPos = [0.06, 0.186, 0.087];
  switch (iniziali.posizione) {
    case 0:
      fontPos = [0.06, 0.186, 0.087];
      break;
    case 1:
      fontPos = [0.06, -0.016, 0.0985];
      break;
    case 2:
      fontPos = [0.06, -0.226, 0.097];
      break;
    default:
      fontPos = [0.06, 0.186, 0.087];
  }

  return (
    <Text
      color={fontCol}
      scale={[0.08, 0.08, 0.6]}
      position={fontPos}
      rotation={[0, 0.3, 0]}
      font={fontFam}
    >
      {iniziali.testo}
    </Text>
  );
};

export default Monogram;
