/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei/useGLTF";

export default function Model(props) {
  const group = useRef();

  const { nodes, materials } = useGLTF("/modelli/placket.glb");

  const { tessuto, bottoni, asole, opa, matBottone, chiusura } = props;

  return (
    <group ref={group} {...props} dispose={null} position={[0, 0, 0.002]}>
      {chiusura !== 2 ? (
        <group>
          <group position={[0, 0.272, 0.036]} rotation={[0.91, 0.02, 0.11]}>
            <mesh
              material={materials.Material5126}
              geometry={nodes.MatShape_7241_Node.geometry}
              scale={[0.5, 0.5, 0.5]}
            >
              <meshMatcapMaterial
                attach="material"
                matcap={matBottone}
                color={bottoni}
              />
            </mesh>
          </group>
          <group position={[0, 0.18, 0.089]} rotation={[1.16, 0.01, 0.06]}>
            <mesh
              material={materials.Material5126}
              geometry={nodes.MatShape_7250_Node.geometry}
              scale={[0.5, 0.5, 0.5]}
            >
              <meshMatcapMaterial
                attach="material"
                matcap={matBottone}
                color={bottoni}
              />
            </mesh>
          </group>
          <group position={[0, 0.08, 0.099]} rotation={[1.61, -0.06, -0.02]}>
            <mesh
              material={materials.Material5126}
              geometry={nodes.MatShape_7259_Node.geometry}
              position={[0, 0, 0]}
              scale={[0.5, 0.5, 0.5]}
            >
              <meshMatcapMaterial
                attach="material"
                matcap={matBottone}
                color={bottoni}
              />
            </mesh>
          </group>
          <group position={[0, -0.02, 0.102]} rotation={[1.59, -0.09, 0.07]}>
            <mesh
              material={materials.Material5126}
              geometry={nodes.MatShape_7268_Node.geometry}
              position={[0, 0, 0]}
              scale={[0.5, 0.5, 0.5]}
            >
              <meshMatcapMaterial
                attach="material"
                matcap={matBottone}
                color={bottoni}
              />
            </mesh>
          </group>
          <group position={[0, -0.13, 0.098]} rotation={[1.59, 0, 0.08]}>
            <mesh
              material={materials.Material5126}
              geometry={nodes.MatShape_7277_Node.geometry}
              scale={[0.5, 0.5, 0.5]}
            >
              <meshMatcapMaterial
                attach="material"
                matcap={matBottone}
                color={bottoni}
              />
            </mesh>
          </group>
          <group position={[0, -0.23, 0.099]} rotation={[1.56, -0.02, 0.14]}>
            <mesh
              material={materials.Material5126}
              geometry={nodes.MatShape_7286_Node.geometry}
              scale={[0.5, 0.5, 0.5]}
            >
              <meshMatcapMaterial
                attach="material"
                matcap={matBottone}
                color={bottoni}
              />
            </mesh>
          </group>

          <mesh
            material={materials.Material2047}
            geometry={nodes.StitchMatShape_11884_Node.geometry}
          >
            <meshStandardMaterial transparent={true} opacity={0.3} />
          </mesh>

          {chiusura > 0 ? (
            <mesh
              material={materials.Material2047}
              geometry={nodes.StitchMatShape_12243_Node.geometry}
            >
              <meshStandardMaterial transparent={true} opacity={0.3} />
            </mesh>
          ) : null}
          <mesh
            material={materials.PT_FABRIC_FRONT_1815}
            geometry={nodes.MatShape_11510_Node.geometry}
            position={[0, 0.271, 0.0352]}
            rotation={[-0.66, -0.51, -1.59]}
            scale={[0.884, 0.995, 0.656]}
          >
            <meshStandardMaterial
              color={asole}
              transparent={true}
              opacity={opa}
            />
          </mesh>
          <mesh
            material={materials["PT_FABRIC_FRONT_1815.001"]}
            geometry={nodes.MatShape_11513_Node.geometry}
            position={[0, 0.18, 0.087]}
            rotation={[-0.42, 0.46, 1.6]}
            scale={[0.884, 0.995, 0.656]}
          >
            <meshStandardMaterial
              color={asole}
              transparent={true}
              opacity={opa}
            />
          </mesh>
          <mesh
            material={materials["PT_FABRIC_FRONT_1815.002"]}
            geometry={nodes.MatShape_11516_Node.geometry}
            position={[0, 0.08, 0.098]}
            rotation={[0.02, 0.05, -1.53]}
            scale={[0.884, 0.995, 0.656]}
          >
            <meshStandardMaterial
              color={asole}
              transparent={true}
              opacity={opa}
            />
          </mesh>
          <mesh
            material={materials["PT_FABRIC_FRONT_1815.003"]}
            geometry={nodes.MatShape_11519_Node.geometry}
            position={[0, -0.02, 0.101]}
            rotation={[0.06, -0.04, -1.51]}
            scale={[0.884, 0.995, 0.656]}
          >
            <meshStandardMaterial
              color={asole}
              transparent={true}
              opacity={opa}
            />
          </mesh>
          <mesh
            material={materials["PT_FABRIC_FRONT_1815.004"]}
            geometry={nodes.MatShape_11522_Node.geometry}
            position={[0, -0.13, 0.097]}
            rotation={[0.11, 0.08, -1.5]}
            scale={[0.884, 0.995, 0.656]}
          >
            <meshStandardMaterial
              color={asole}
              transparent={true}
              opacity={opa}
            />
          </mesh>
          <mesh
            material={materials["PT_FABRIC_FRONT_1815.005"]}
            geometry={nodes.MatShape_11525_Node.geometry}
            position={[0, -0.23, 0.098]}
            rotation={[0.08, 0.19, -1.51]}
            scale={[0.884, 0.995, 0.656]}
          >
            <meshStandardMaterial
              color={asole}
              transparent={true}
              opacity={opa}
            />
          </mesh>
        </group>
      ) : null}
      {chiusura > 0 ? (
        <mesh
          material={materials["FABRIC 1_FRONT_12350"]}
          geometry={nodes.Body_Front_Placket_Node.geometry}
        >
          <meshStandardMaterial
            map={tessuto}
            roughness={1}
            toneMapped={false}
          />
        </mesh>
      ) : null}
    </group>
  );
}

useGLTF.preload("/modelli/placket.glb");
