import React from "react";
import { connect } from "react-redux";
import { aggiornamisure, aggiornafit } from "../../state/actions";
import MisuraNeutra from "../misuraneutra";

const MisuraDaTaglia = (props) => {
  const { lingua, fit, aggMisure, aggFit, taglia } = props;

  const selectFit = (event) => {
    aggFit(event.target.value);
  };

  const selectChange = (event) => {
    let mis = {};
    switch (event.target.value) {
      case "xs":
        mis = {
          girovita: 88,
          torace: 92,
          braccia: 65,
          polso: 21,
          spalle: 43.5,
          collo: 38,
          lunghezza: 77,
          taglia: "xs",
        };
        break;
      case "s":
        mis = {
          girovita: 88,
          torace: 92,
          braccia: 65,
          polso: 21,
          spalle: 43.5,
          collo: 38,
          lunghezza: 77,
          taglia: "s",
        };
        break;
      case "m":
        mis = {
          girovita: 96,
          torace: 98,
          braccia: 65,
          polso: 22,
          spalle: 46.5,
          collo: 39.5,
          lunghezza: 78,
          taglia: "m",
        };
        break;
      case "l":
        mis = {
          girovita: 102,
          torace: 104,
          braccia: 66,
          polso: 23,
          spalle: 48,
          collo: 41.5,
          lunghezza: 79,
          taglia: "l",
        };
        break;
      case "xl":
        mis = {
          girovita: 113,
          torace: 112,
          braccia: 66.5,
          polso: 23.5,
          spalle: 50.5,
          collo: 43.5,
          lunghezza: 80,
          taglia: "xl",
        };
        break;
      case "xxl":
        mis = {
          girovita: 118,
          torace: 116,
          braccia: 67.5,
          polso: 24.5,
          spalle: 52.5,
          collo: 45.5,
          lunghezza: 81,
          taglia: "xxl",
        };
        break;
      case "xxxl":
        mis = {
          girovita: 124,
          torace: 122,
          braccia: 68,
          polso: 25,
          spalle: 53.5,
          collo: 47.5,
          lunghezza: 82,
          taglia: "xxxl",
        };
        break;
      default:
        mis = {
          girovita: 102,
          torace: 104,
          braccia: 66,
          polso: 23,
          spalle: 48,
          collo: 41.5,
          lunghezza: 79,
          taglia: "l",
        };
    }
    aggMisure(mis);
  };

  return (
    <div>
      <div className="field" style={{ marginBottom: "1.5rem" }}>
        <label className="label">
          {lingua.navigazione.misure.selezionaTaglia}
        </label>
        <div className="control">
          <div className="select">
            <select
              value={taglia}
              onBlur={selectChange}
              onChange={selectChange}
            >
              <option value="xs">{lingua.navigazione.misure.xs}</option>
              <option value="s">{lingua.navigazione.misure.s}</option>
              <option value="m">{lingua.navigazione.misure.m}</option>
              <option value="l">{lingua.navigazione.misure.l}</option>
              <option value="xl">{lingua.navigazione.misure.xl}</option>
              <option value="xxl">{lingua.navigazione.misure.xxl}</option>
              <option value="xxxl">{lingua.navigazione.misure.xxxl}</option>
            </select>
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">
          {lingua.navigazione.misure.selezionaVestibilita}
        </label>
        <div className="control">
          <div className="select">
            <select value={fit} onBlur={selectFit} onChange={selectFit}>
              <option value="Slim">Slim</option>
              <option value="Regular">Regular</option>
              <option value="Large">Large</option>
            </select>
          </div>
        </div>
      </div>

      <MisuraNeutra lingua={lingua} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    taglia: state.shop.camicia.misure.taglia,
    fit: state.shop.camicia.misure.fit,
    tipo: state.shop.camicia.misure.tipo,
    sBraccia: state.shop.camicia.misure.braccia,
    sCollo: state.shop.camicia.misure.collo,
    sGirovita: state.shop.camicia.misure.girovita,
    sLunghezza: state.shop.camicia.misure.lunghezza,
    sPolso: state.shop.camicia.misure.polso,
    sSpalle: state.shop.camicia.misure.spalle,
    sTorace: state.shop.camicia.misure.torace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    aggMisure: (val) => dispatch(aggiornamisure(val)),
    aggFit: (val) => dispatch(aggiornafit(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MisuraDaTaglia);
