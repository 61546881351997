export const wrapTex = {
  "MP1-KS389-9900": {
    repeat_x: 0.12,
    repeat_y: 0.12,
  },
  "MP1-KS389-5904": {
    repeat_x: 0.25,
    repeat_y: 0.1093,
  },
  "MP1-S385-5901": {
    repeat_x: 0.20,
    repeat_y: 0.14,
  },
  "MP1-S1033-6900": {
    repeat_x: 0.42,
    repeat_y: 0.32,
  },
  "MP1-S1033-5901": {
    repeat_x: 0.42,
    repeat_y: 0.32,
  },
  "MP1-S385-4903": {
    repeat_x: 0.32,
    repeat_y: 0.03,
  },
  "MP1-KS389-6902": {
    repeat_x: 0.12,
    repeat_y: 0.12,
  },
  "MP1-KS389-4900": {
    repeat_x: 0.12,
    repeat_y: 0.12,
  },
  "MP1-S386-5901": {
    repeat_x: 0.20,
    repeat_y: 0.02,
  },
  "MP1-KS1052-5901": {
    repeat_x: 0.42,
    repeat_y: 0.42,
  },
  "MP1-S385-5900": {
    repeat_x: 0.42,
    repeat_y: 0.42,
  },
  "MP1-S389-1901": {
    repeat_x: 0.2,
    repeat_y: 0.02,
  },
  "MP1-S386-5900": {
    repeat_x: 0.16,
    repeat_y: 0.002,
  },
  "MP1-S385-1901": {
    repeat_x: 0.38,
    repeat_y: 0.02,
  },
  "MP1-S1033-5900": {
    repeat_x: 0.42,
    repeat_y: 0.02,
  },
  "MP1-S385-5902": {
    repeat_x: 0.42,
    repeat_y: 0.12,
  },
  "MP1-S390-5901": {
    repeat_x: 0.2,
    repeat_y: 0.02,
  },
  "MP1-KS1052-5902": {
    repeat_x: 0.42,
    repeat_y: 0.002,
  },
  "MP1-S388-4900": {
    repeat_x: 0.42,
    repeat_y: 0.002,
  },
  "MP1-S1033-1900": {
    repeat_x: 0.62,
    repeat_y: 0.02,
  },
  "MP1-S388-6900": {
    repeat_x: 0.52,
    repeat_y: 0.002,
  },
  "MP1-S388-1903": {
    repeat_x: 0.52,
    repeat_y: 0.002,
  },
  "MP1-S388-1902": {
    repeat_x: 0.52,
    repeat_y: 0.002,
  },
  "MP1-S388-5901": {
    repeat_x: 0.52,
    repeat_y: 0.002,
  },
  "MP1-S390-1900": {
    repeat_x: 0.28,
    repeat_y: 0.002,
  },
  "MP1-S385-6900": {
    repeat_x: 0.32,
    repeat_y: 0.002,
  },
  "MP1-KS1052-5900": {
    repeat_x: 0.52,
    repeat_y: 0.002,
  },
  "MP1-S390-5902": {
    repeat_x: 0.28,
    repeat_y: 0.002,
  },
  "MP1-S390-6900": {
    repeat_x: 0.16,
    repeat_y: 0.002,
  },
  "MP1-S389-5900": {
    repeat_x: 0.16,
    repeat_y: 0.002,
  },
  "MP1-S389-1903": {
    repeat_x: 0.12,
    repeat_y: 0.02,
  },
  "MP1-S386-5902": {
    repeat_x: 0.16,
    repeat_y: 0.002,
  },
  "MP1-S385-1904": {
    repeat_x: 0.32,
    repeat_y: 0.002,
  },
  "MP1-S389-5902": {
    repeat_x: 0.12,
    repeat_y: 0.02,
  },
  "MOC1-KL702-4900": {
    repeat_x: 0.1,
    repeat_y: 0.29,
  },
  "MOC1-KL702-2900": {
    repeat_x: 0.12,
    repeat_y: 1,
  },
  "MOC1-KL702-5900": {
    repeat_x: 0.12,
    repeat_y: 1,
  },
  "MOC1-W269-2900": {
    repeat_x: 1,
    repeat_y: 1,
  },
  "MOC1-W269-1902": {
    repeat_x: 1,
    repeat_y: 1,
  },
  "MOC1-W269-5900": {
    repeat_x: 1,
    repeat_y: 1,
  },
  "MFF1-KF1047-7901": {
    repeat_x: 1,
    repeat_y: 1,
  },
  "MFF1-KF1047-6901": {
    repeat_x: 1,
    repeat_y: 1,
  },
  "MP1-B100-5336": {
    repeat_x: 1,
    repeat_y: 1,
  },
  "MP1-B100-5758": {
    repeat_x: 1,
    repeat_y: 1,
  },
  "MP1-B100-5354": {
    repeat_x: 1,
    repeat_y: 1,
  },
  "MOC1-W269-5903": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MP1-B100-0000": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MOC1-W269-8902": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MFF1-F392-7900": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MFF1-F392-5900": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MOC1-W269-7902": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MOC1-W269-1901": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MOC1-W269-3900": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MFF1-IH1050-7900": {
    repeat_x: 1.2,
    repeat_y: 1.2,
  },
  "MOC1-A100-0000": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MOC1-W269-6902": {
    repeat_x: 0.00011,
    repeat_y: 1,
  },
  "MP1-B100-5760": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MFF1-F392-1900": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MFF1-501-5050": {
    repeat_x: 0.912,
    repeat_y: 1,
  },
  "MOC1-W269-7903": {
    repeat_x: 0.952,
    repeat_y: 1,
  },
  "MOC1-W269-1900": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MFF1-KG1049-5901": {
    repeat_x: 0.952,
    repeat_y: 1,
  },
  "MFF1-F392-8900": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MOC1-W269-5902": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MFF1-F392-6900": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MOC1-W269-6903": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MFF1-501-5051": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MP1-B100-5759": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MOC1-W269-5901": {
    repeat_x: 0.012,
    repeat_y: 1,
  },
  "MP1-S389-5901": {
    repeat_x: 0.2,
    repeat_y: 1,
  },
  "MOC2-L527-5900": {
    repeat_x: 0.2,
    repeat_y: 3,
  },
  "MOC2-L527-2900": {
    repeat_x: 0.2,
    repeat_y: 3,
  },
  "MOC2-L527-1900": {
    repeat_x: 0.2,
    repeat_y: 3,
  },
  "MOC2- L527-8900": {
    repeat_x: 0.2,
    repeat_y: 3,
  },
  "MOC2-L524-5900": {
    repeat_x: 0.2,
    repeat_y: 3,
  },
  "MOC2-L524-8900": {
    repeat_x: 0.2,
    repeat_y: 3,
  },
  "MOC2- L524-1900": {
    repeat_x: 0.2,
    repeat_y: 3,
  },
  "MOC2-L524-2901": {
    repeat_x: 0.2,
    repeat_y: 3,
  },
  "MOC2-L524-1901": {
    repeat_x: 0.2,
    repeat_y: 3,
  },
  "MP2-KS620-1900": {
    repeat_x: 0.3,
    repeat_y: 1,
  },
  "MP2-KS621-1900": {
    repeat_x: 0.4,
    repeat_y: 1,
  },
  "MP2-KS622-1900": {
    repeat_x: 0.4,
    repeat_y: 1,
  },
  "MP2-KS620-5900": {
    repeat_x: 0.2,
    repeat_y: 1,
  },
  "MP2-KS621-5900": {
    repeat_x: 0.4,
    repeat_y: 1,
  },
  "MP2-KS622-5900": {
    repeat_x: 0.4,
    repeat_y: 1,
  },
  "MP2-KS620-1901": {
    repeat_x: 0.2,
    repeat_y: 1,
  },
  "MP2-KS621-1901": {
    repeat_x: 0.4,
    repeat_y: 1,
  },
  "MP2-KS622-1901": {
    repeat_x: 0.4,
    repeat_y: 1,
  },
  "MP2-KS620-7900": {
    repeat_x: 0.2,
    repeat_y: 1,
  },
  "MP2-KS621-7900": {
    repeat_x: 0.4,
    repeat_y: 1,
  },
  "MP2-KS622-7900": {
    repeat_x: 0.4,
    repeat_y: 1,
  },
  "MP1-C1034-1900": {
    repeat_x: 0.18,
    repeat_y: 0.32,
  },
  "MP1-C1034-6900": {
    repeat_x: 0.14,
    repeat_y: 0.20,
  },
  "MP1-C1034-5900": {
    repeat_x: 0.18,
    repeat_y: 0.32,
  },
  "MP1-C1034-5901": {
    repeat_x: 0.18,
    repeat_y: 0.22,
  },
  "MP1-KD1053-5900": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "MP1-KD1053-5901": {
    repeat_x: 0.2,
    repeat_y: 0.3,
  },
  "MP1-KD1053-5902": {
    repeat_x: 0.4,
    repeat_y: 0.2,
  },
  "MP1-C391-5900": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP1-C391-5901": {
    repeat_x: 0.5,
    repeat_y: 0.5,
  },
  "MP1-KC1054-5900": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP1-KC1054-5901": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP1-KC1054-5902": {
    repeat_x: 0.15,
    repeat_y: 0.15,
  },
  "MP2-C156-1904": {
    repeat_x: 0.40,
    repeat_y: 0.40,
  },
  "MP2-C156-5900": {
    repeat_x: 0.3,
    repeat_y: 0.6,
  },
  "MP2-C156-5902": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "MP2-C156-5906": {
    repeat_x: 0.4,
    repeat_y: 0.6,
  },
  "MP2-C156-8902": {
    repeat_x: 0.4,
    repeat_y: 0.6,
  },
  "MP2-C156-6904": {
    repeat_x: 0.3,
    repeat_y: 0.6,
  },
  "MP2-C156-1903": {
    repeat_x: 0.4,
    repeat_y: 0.6,
  },
  "MP2-C156-9900": {
    repeat_x: 0.4,
    repeat_y: 0.6,
  },
  "MP2-C345-5901": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-C345-5902": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-C345-4902": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-C345-6902": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-C345-1901": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-C345-9900": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-D163-1950": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-D163-5950": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-D163-5951": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-D163-6950": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-D163-9950": {
    repeat_x: 0.2,
    repeat_y: 0.2,
  },
  "MP2-D164-1951": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "MP2-D164-5900": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "MP2-D164-5901": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "MP2-D164-5902": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "MP2-D164-6952": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "MP2-D164-9950": {
    repeat_x: 0.4,
    repeat_y: 0.4,
  },
  "MP2-D164-9950": {
     repeat_x: 0.4, 
     repeat_y: 0.4,
     }, 
  "MP2-122-0000": {
     repeat_x: 0.4, 
     repeat_y: 0.4,
    }, 
  "MP2-B122-0016": {
     repeat_x: 1, 
     repeat_y: 1,
    },
  "MP2-B122-1876": {
      repeat_x: 0.4, 
      repeat_y: 0.4,
     },
  "MP2-W138-5903": {
      repeat_x: 0.7, 
      repeat_y: 0.7,
     },
  "MP2-B122-267": {
      repeat_x: 1, 
      repeat_y: 1,
     },
     "MP2-B122-2086": {
      repeat_x: 1, 
      repeat_y: 1,
     },
     "MP2-B122-6399": {
      repeat_x: 1, 
      repeat_y: 1,
     },
     "MP2-B122-5778": {
      repeat_x: 1, 
      repeat_y: 1,
     },
     "MP2-B122-6319": {
      repeat_x: 1, 
      repeat_y: 1,
     },
     "MP2-B122-6500": {
      repeat_x: 0.9, 
      repeat_y: 1,
     },
     "MP2-B122-5354": {
      repeat_x: 0.4, 
      repeat_y: 0.4,
     },
     "MP2-S154-5900": {
      repeat_x: 1, 
      repeat_y: 1,
     },
     "MP2-KS632-6900": {
      repeat_x: 1, 
      repeat_y: 1,
     },
     "MP2-S154-5901": {
      repeat_x: 1, 
      repeat_y: 1,
     },
     "MP2-KS632-5900": {
      repeat_x: 1, 
      repeat_y: 1,
     },
     "MP2-S155-1904": {
      repeat_x: 0.6, 
      repeat_y: 3,
     },
     "MP2-S155-5900": {
      repeat_x: 0.6, 
      repeat_y: 3,
     },
     "MP2-S155-5902": {
      repeat_x: 0.4, 
      repeat_y: 3,
     },
     "MP2-S155-5901": {
      repeat_x: 0.4, 
      repeat_y: 3,
     },
     "MP2-S155-8902": {
      repeat_x: 0.4, 
      repeat_y: 3,
     },
     "MP2-S155-6904": {
      repeat_x: 0.4, 
      repeat_y: 3,
     },
     "MP2-S155-1903": {
      repeat_x: 0.4, 
      repeat_y: 3,
     },
     "MP2-S155-9900": {
      repeat_x: 0.4, 
      repeat_y: 3,
     },
     "MP2-S136-1901": {
      repeat_x: 0.4, 
      repeat_y: 1,
     },
     "MP2-S136-5900": {
      repeat_x: 0.4, 
      repeat_y: 1,
     },
     "MP2-S136-5901": {
      repeat_x: 0.4, 
      repeat_y: 1,
     },
     "MP2-S136-5903": {
      repeat_x: 0.4, 
      repeat_y: 1,
     },
     "MP2-S136-6902": {
      repeat_x: 0.4, 
      repeat_y: 1,
     },
     "MP2-S136-1905": {
      repeat_x: 0.4, 
      repeat_y: 1,
     },
     "MP2-S136-9900": {
      repeat_x: 0.4, 
      repeat_y: 1,
     },
     "ML3-B531-0000": {
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
       }, 
    "ML3-IW2121-2900": {
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
       }, 
    "ML3-IW2121-2901": {
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
       }, 
    "ML3-IW2121-4900": {
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
       }, 
    "ML3-W534-8901": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092, 
    }, 
    "ML3-IW2121-3900": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
    },
    "ML3-IW2121-1900": {
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
       }, 
    "ML3-IW2121-1901": {
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
       }, 
    "ML3-IW2121-5900": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092, 
    }, 
    "ML3-W534-1902": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
     }, 
    "ML3-IW2121-6900": {
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
      }, 
    "ML3-W534-5900": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092, 
      }, 
    "ML3-W534-5901": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
      }, 
    "ML3-IW2121-5901": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
      }, 
    "ML3-IW2121-9900": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
    }, 
    "ML3-Y535-5903": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
    }, 
    "ML3-Z2122-5354": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
    }, 
    "ML3-RW534-5904": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092, 
    }, 
    "ML3-S625-5900-cel": { 
      repeat_x: 0.32, 
      repeat_y: 1, 
    }, 
    "ML3-S501-5901": { 
      repeat_x: 0.24, 
      repeat_y: 1, 
    }, 
    "ML3-S542-5901-cel": { 
      repeat_x: 0.14, 
      repeat_y: 1, 
    }, 
    "ML3-S625-5900-blu": { 
      repeat_x: 0.32, 
      repeat_y: 1.5, 
    }, 
    "ML3-S542-5902": { 
      repeat_x: 0.34, 
      repeat_y: 1, 
    }, 
    "ML3-S625-5900-rosso": { 
      repeat_x: 0.32, 
      repeat_y: 1, 
    }, 
    "ML3-S542-5901-rosso": { 
      repeat_x: 0.3, 
      repeat_y: 4, 
    }, 
    "ML3-IS668-5900": { 
      repeat_x: 0.0092, 
      repeat_y: 0.0092,
    }, 
    "ML3-C626-5900": { 
      repeat_x: 0.40, 
      repeat_y: 0.40, 
    }, 
    "ML3-C522-5900": { 
      repeat_x: 0.20, 
      repeat_y: 0.18, 
    }, 
    "ML3-IC669-5900": { 
      repeat_x: 0.05, 
      repeat_y: 0.05, 
    }, 
    "ML3-IS667-5900": { 
      repeat_x: 0.2, 
      repeat_y: 0.2, 
    }, 
    "ML3-S501-1900": { 
      repeat_x: 0.32, 
      repeat_y: 1
    }, 
    "ML3-S501-5904": { 
      repeat_x: 0.24, 
      repeat_y: 1, 
    }, 
    "MGALLESS3-P2046-1900": { 
      repeat_x: 0.10, 
      repeat_y: 0.14,
    }, 
    "MGALLESS3-P2046-6900": { 
      repeat_x: 0.10, 
      repeat_y: 0.14, 
    }, 
    "MGALLESS3-P2046-9900": { 
      repeat_x: 0.10, 
      repeat_y: 0.14, 
    }, 
    "MGALLESS3-P2046-5900": { 
      repeat_x: 0.10, 
      repeat_y: 0.14, 
    }, 
    "MGALLESS3-P2046-5901": { 
      repeat_x: 0.10, 
      repeat_y: 0.14, 
    }, 
    "MPIEDEPOUL-KO132-5901": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "MPIEDEPOUL-KO131-5902": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "MPIEDEPOUL-KO132-6900": { 
      repeat_x: 0.5, 
      repeat_y: 0.3, 
    }, 
    "MPIEDEPOUL-KO132-5902": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "P64941-1000": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "P64941-01": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "P64941-02 ": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "P64955-01": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "P64957-01": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "P64957-02": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "P66456-01": { 
      repeat_x: 0.3, 
      repeat_y: 0.9, 
    }, 
    "P66456-02": { 
      repeat_x: 0.3, 
      repeat_y: 0.9, 
    }, 
    "P66456-03": { 
      repeat_x: 0.3, 
      repeat_y: 0.9, 
    }, 
    "P66457-01": { 
      repeat_x: 0.2, 
      repeat_y: 0.9, 
    }, 
    "P66457-02": { 
      repeat_x: 0.2, 
      repeat_y: 0.9, 
    }, 
    "P66458-01": { 
      repeat_x: 0.15, 
      repeat_y: 0.9, 
    }, 
    "P66458-02": { 
      repeat_x: 0.15, 
      repeat_y: 0.9, 
    }, 
    "P66461-01": { 
      repeat_x: 0.3, 
      repeat_y: 0.9, 
    }, 
    "P66462-01": { 
      repeat_x: 0.4, 
      repeat_y: 0.4, 
    }, 
    "P66466-01": { 
      repeat_x: 0.3, 
      repeat_y: 0.7, 
    }, 
    "F69629-01": { 
      repeat_x: 0.7, 
      repeat_y: 0.9, 
    }, 
    "F69629-02": { 
      repeat_x: 0.5, 
      repeat_y: 0.9, 
    }, 
    "F69631-01": { 
      repeat_x: 0.3, 
      repeat_y: 0.7, 
    }, 
    "F69631-03": { 
      repeat_x: 0.3, 
      repeat_y: 0.7, 
    }, 
    "F69631-07": { 
      repeat_x: 0.3, 
      repeat_y: 0.7, 
    }, 
    "P58511-50408": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "P58512-03": { 
      repeat_x: 0.5, 
      repeat_y: 0.7, 
    }, 
    "Denim-chiaro": { 
      repeat_x: 0.099, 
      repeat_y: 0.077, 
    }, 
    "Denim-scuro": { 
      repeat_x: 0.099, 
      repeat_y: 0.077, 
    }, 
     
};

export const wrapCont = {
  "0": {
    repeat_x: 0.1,
    repeat_y: 0.1,
  },
  "1": {
    repeat_x: 0.025,
    repeat_y: 0.025,
  },
  "2": {
    repeat_x: 0.095,
    repeat_y: 0.095,
  },
  "3": {
    repeat_x: 0.065,
    repeat_y: 0.065,
  },
};
