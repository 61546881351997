import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Fianco = (props) => {
  const { lingua, tipoTut } = props;

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { regex: "/tutorial/fianchi.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      {tipoTut === "corpo" ? (
        <div>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt={lingua.tutorial.fianco.titolo}
            style={{ marginBottom: "1rem" }}
          />
          <p className="title is-5 is-uppercase has-text-weight-semibold">
            {lingua.tutorial.fianco.titolo}
          </p>
          <p className="is-size-6">{lingua.tutorial.fianco.descrizione}</p>
          <p style={{ marginTop: "2rem" }}>
            <a
              href="https://www.youtube.com/watch?v=eMG7b36uE0E"
              target="_blank"
            >
              VIDEO TUTORIAL
            </a>
          </p>
        </div>
      ) : null}
    </>
  );
};

export default Fianco;
