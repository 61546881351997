export const navColletti = [
  {
    _id: 0,
    nome_it: "Colletto inglese",
    nome_en: "English collar",
    nome_fr: "Cou anglais",
    nome_es: "Cuello inglés",
    nome_ca: "Colletto italiano",
  },
  {
    _id: 1,
    nome_it: "Colletto italiano",
    nome_en: "Classic italian collar",
    nome_fr: "Cou italien",
    nome_es: "Cuello italiano",
    nome_ca: "Colletto italiano classico",
  },
  {
    _id: 2,
    nome_it: "Colletto francese più aperto",
    nome_en: "French wide collar",
    nome_fr: "Cou francais large",
    nome_es: "Cuello francés ancho",
    nome_ca: "Colletto francese classico",
  },
  {
    _id: 3,
    nome_it: "Colletto francese",
    nome_en: "French collar",
    nome_fr: "Cou francais",
    nome_es: "Cuello francés",
    nome_ca: "Colletto francese semiaperto",
  },
  {
    _id: 4,
    nome_it: "Colletto button down classico",
    nome_en: "Button down collar",
    nome_fr: "Cou amèricain button down",
    nome_es: "Cuello button down",
    nome_ca: "Colletto botton down classico",
  },
  {
    _id: 5,
    nome_it: "Colletto per cerimonie diplomatico",
    nome_en: "Winged collar",
    nome_fr: "Col cassé ",
    nome_es: "Cuello diplomático",
    nome_ca: "Colletto diplomatico",
  },
  {
    _id: 6,
    nome_it: "Colletto coreano",
    nome_en: "Stand up collar",
    nome_fr: "Cou coréen",
    nome_es: "Cuello Mao",
    nome_ca: "Colletto coreano",
  },
  {
    _id: 7,
    nome_it: "Colletto per cerimonie francese",
    nome_en: "French Ceremony collar",
    nome_fr: "Cou français pour cérémonie",
    nome_es: "Cuello francés para ceremonia",
    nome_ca: "Colletto coreano",
  },
];

export const navPolsini = [
  {
    _id: 0,
    nome_it: "Polsino arrotondato",
    nome_en: "Rounded cuffs",
    nome_fr: "Poignet ronde",
    nome_es: "Puño redondeado",
    nome_ca: "Polsino arrotondato",
  },
  {
    _id: 1,
    nome_it: "Polsino smussato",
    nome_en: "Beveled cuffs",
    nome_fr: "Poignet carre",
    nome_es: "Puño carre",
    nome_ca: "Polsino smussato",
  },
  {
    _id: 2,
    nome_it: "Polsino dritto",
    nome_en: "Straight cuff",
    nome_fr: "Manchette droite",
    nome_es: "Puño recto",
    nome_ca: "Polsino dritto",
  },
  {
    _id: 3,
    nome_it: "Polsino gemelli",
    nome_en: "Cufflinks cuffs",
    nome_fr: "Poignet manchette",
    nome_es: "Puño biselado",
    nome_ca: "Polsino gemelli",
  },
  {
    _id: 4,
    nome_it: "Manica corta",
    nome_en: "Short sleeve",
    nome_fr: "Manche courte",
    nome_es: "Manga corta",
    nome_ca: "Manica corta",
  },
];

export const navChiusure = [
  {
    _id: 0,
    nome_it: "Chiusura liscia con bottoni a vista",
    nome_en: "Smooth closure with visible buttons",
    nome_fr: "Fermeture lisse avec boutons visibles",
    nome_es: "Cierre liso con botones visibles",
    nome_ca: "Francese",
  },
  {
    _id: 1,
    nome_it: "Cannello sul davanti",
    nome_en: "Standard closing",
    nome_fr: "Fermeture americaine",
    nome_es: "Cierre estándar",
    nome_ca: "Standard",
  },
  {
    _id: 2,
    nome_it: "Bottoni coperti",
    nome_en: "Hidden closing",
    nome_fr: "Fermeture cachè",
    nome_es: "Cierre oculto",
    nome_ca: "Nascosta",
  },
];

export const navTaschini = [
  {
    _id: 0,
    nome_it: "Nessun taschino",
    nome_en: "No pocket",
    nome_fr: "Sans poches",
    nome_es: "No bolsillo",
    nome_ca: "Nessun taschino",
  },
  {
    _id: 1,
    nome_it: "Taschino arrotondato",
    nome_en: "Rounded pocket",
    nome_fr: "Poche arrondie",
    nome_es: "Bolsillo redondeado",
    nome_ca: "Taschino arrotondato",
  },
  {
    _id: 2,
    nome_it: "Taschino smussato",
    nome_en: "Beveled pocket",
    nome_fr: "Poche carre",
    nome_es: "Bolsillo biselado",
    nome_ca: "Taschino smussato",
  },
  {
    _id: 3,
    nome_it: "Due taschini arrotondati",
    nome_en: "Two rounded pockets",
    nome_fr: "Deux poches arrondie",
    nome_es: "Dos bolsillos redondeados",
    nome_ca: "Due taschini arrotondati",
  },
  {
    _id: 4,
    nome_it: "Due taschini smussati",
    nome_en: "Two beveled pockets",
    nome_fr: "Deux poches carre",
    nome_es: "Dos bolsillos biselados",
    nome_ca: "Due taschini smussati",
  },
];

export const navPinces = [
  {
    _id: 0,
    nome_it: "Dietro liscio",
    nome_en: "No pences",
    nome_fr: "Sans pinces",
    nome_es: "Detrás liso",
    nome_ca: "Dietro Liscio",
  },
  {
    _id: 1,
    nome_it: "Dietro con cannello",
    nome_en: "One pence",
    nome_fr: "Pinces au centre",
    nome_es: "Detrás con pinza central",
    nome_ca: "Dietro con doppia piega",
  },
  {
    _id: 2,
    nome_it: "Dietro con pences",
    nome_en: "Double pences",
    nome_fr: "Double pinces",
    nome_es: "Detrás con pinzas dobles",
    nome_ca: "Dietro con doppie pinces",
  },
  {
    _id: 3,
    nome_it: "Dietro con piegoni",
    nome_en: "Side pleated",
    nome_fr: "Derrière avec des coups",
    nome_es: "Detrás con tiros",
    nome_ca: "Darrere amb trets",
  },
];

export const navBottoni = [
  {
    _id: 0,
    nome_it: "Bottone Bianco 1",
    nome_en: "White buttons 1",
    nome_fr: "Boutons blanc 1",
    nome_es: "Botons blancos 1",
    nome_ca: "Bottone Bianco 1",
  },
  {
    _id: 1,
    nome_it: "Bottone Bianco 2",
    nome_en: "White buttons 2",
    nome_fr: "Boutons blanc 2",
    nome_es: "Botons blancos 2",
    nome_ca: "Bottone Bianco",
  },
  {
    _id: 2,
    nome_it: "Bottone Bianco 3",
    nome_en: "White buttons 3",
    nome_fr: "Boutons blanc 3",
    nome_es: "Botons blancos 3",
    nome_ca: "Bottone Bianco 3",
  },
  {
    _id: 3,
    nome_it: "Bottone Blu 1",
    nome_en: "Blue buttons 1",
    nome_fr: "Boutons bleu 1",
    nome_es: "Botons azules 1",
    nome_ca: "Bottone Blu 1",
  },
  {
    _id: 4,
    nome_it: "Bottone Blu 2",
    nome_en: "Blue buttons 2",
    nome_fr: "Boutons bleu 2",
    nome_es: "Botons azules 2",
    nome_ca: "Bottone Blu",
  },
  {
    _id: 5,
    nome_it: "Bottone Blu 3",
    nome_en: "Blue buttons 3",
    nome_fr: "Boutons bleu 3",
    nome_es: "Botons azules 3",
    nome_ca: "Bottone Blu",
  },
  {
    _id: 6,
    nome_it: "Bottone Grigio 1",
    nome_en: "Grey buttons 1",
    nome_fr: "Boutons gris 1",
    nome_es: "Botons grises 1",
    nome_ca: "Bottone Grigio 1",
  },
  {
    _id: 7,
    nome_it: "Bottone Grigio 2",
    nome_en: "Grey buttons 2",
    nome_fr: "Boutons gris 2",
    nome_es: "Botons grises 2",
    nome_ca: "Bottone Grigio 2",
  },
  {
    _id: 8,
    nome_it: "Bottone Grigio 3",
    nome_en: "Grey buttons 3",
    nome_fr: "Boutons gris 3",
    nome_es: "Botons grises 3",
    nome_ca: "Bottone Grigio",
  },
  {
    _id: 9,
    nome_it: "Bottone Nero 1",
    nome_en: "Black buttons 1",
    nome_fr: "Boutons noir 1",
    nome_es: "Botons negros 1",
    nome_ca: "Bottone Nero",
  },
  {
    _id: 10,
    nome_it: "Bottone Nero 2",
    nome_en: "Black buttons 2",
    nome_fr: "Boutons noir 2",
    nome_es: "Botons negros 2",
    nome_ca: "Bottone Nero",
  },
  {
    _id: 11,
    nome_it: "Bottone Nero 3",
    nome_en: "Black buttons 3",
    nome_fr: "Boutons noir 3",
    nome_es: "Botons negros 3",
    nome_ca: "Bottone Nero",
  },
];

export const navAsole = [
  {
    _id: -1,
    nome_it: "Asola colore camicia",
    nome_en: "Asola colore camicia",
    nome_es: "Asola colore camicia",
    nome_fr: "Asola colore camicia",
    nome_ca: "Asola colore camicia",
  },
  {
    _id: 0,
    nome_it: "Asola Bianca",
    nome_en: "White buttonholes",
    nome_es: "Ojal blanco",
    nome_fr: "Boutonnière blanc",
    nome_ca: "Asola Bianca",
  },
  {
    _id: 1,
    nome_it: "Asola Blu",
    nome_en: "Blue buttonholes",
    nome_fr: "Boutonnière bleu",
    nome_es: "Ojal azul",
    nome_ca: "Asola Blu",
  },
  {
    _id: 2,
    nome_it: "Asola Celeste",
    nome_en: "Light blue buttonholes",
    nome_fr: "Boutonnière bleu clair",
    nome_es: "Ojal celeste",
    nome_ca: "Asola Celeste",
  },
  {
    _id: 3,
    nome_it: "Asola Grigia",
    nome_en: "Grey buttonholes",
    nome_fr: "Boutonnière gris",
    nome_es: "Ojal gris",
    nome_ca: "Asola Grigia",
  },
  {
    _id: 4,
    nome_it: "Asola Nera",
    nome_en: "Black buttonholes",
    nome_fr: "Boutonnière noir",
    nome_es: "Ojal negro",
    nome_ca: "Asola Nera",
  },
  {
    _id: 5,
    nome_it: "Asola Rossa",
    nome_en: "Red Buttonholes",
    nome_fr: "Boutonnière rouge",
    nome_es: "Ojal rojo",
    nome_ca: "Asola Rossa",
  },
  {
    _id: 6,
    nome_it: "Asola Arancione",
    nome_en: "Orange Buttonholes",
    nome_fr: "Boutonnière orange",
    nome_es: "Ojal naranja",
    nome_ca: "Asola arancione",
  },
  {
    _id: 7,
    nome_it: "Asola Verde",
    nome_en: "Green Buttonholes",
    nome_fr: "Boutonnière vert",
    nome_es: "Ojal verde",
    nome_ca: "Asola verde",
  },
  {
    _id: 8,
    nome_it: "Asola Rosa",
    nome_en: "Pink Buttonholes",
    nome_fr: "Boutonnière rose",
    nome_es: "Ojal rosada",
    nome_ca: "Asola Rosa",
  },
];

export const navFili = [
  {
    _id: 0,
    nome_it: "Filo bianco",
    nome_en: "White thread",
    nome_fr: "Fil blanc",
    nome_es: "Hilo blanco",
    nome_ca: "Filo bianco",
  },
  {
    _id: 1,
    nome_it: "Filo blu",
    nome_en: "Blu thread",
    nome_fr: "Fil bleu",
    nome_es: "Hilo azul",
    nome_ca: "Filo Blu",
  },
  {
    _id: 2,
    nome_it: "Filo celeste",
    nome_en: "Light blue thread",
    nome_fr: "Fil bleu clair",
    nome_es: "Hilo celeste",
    nome_ca: "Filo Celeste",
  },
  {
    _id: 3,
    nome_it: "Filo grigio",
    nome_en: "Grey thread",
    nome_fr: "Fil gris",
    nome_es: "Hilo gris",
    nome_ca: "Filo Grigio",
  },
  {
    _id: 4,
    nome_it: "Filo nero",
    nome_en: "Black thread",
    nome_fr: "Fil noir",
    nome_es: "Hilo negro",
    nome_ca: "Filo Nero",
  },
  {
    _id: 5,
    nome_it: "Filo rosso",
    nome_en: "Red thread",
    nome_fr: "Fil rouge",
    nome_es: "Hilo rojo",
    nome_ca: "Filo rosso",
  },
];

export const navContrasti = [
  {
    _id: 0,
    nome_it: "Nessun contrasto",
    nome_en: "No contrast",
    nome_fr: "No contraste",
    nome_es: "No contraste",
    nome_ca: "Nessun Contrasto",
  },
  {
    _id: 1,
    nome_it: "Hutch 2441S-W21220-0007",
    nome_en: "Hutch 2441S-W21220-0007",
    nome_fr: "Hutch 2441S-W21220-0007",
    nome_es: "Hutch 2441S-W21220-0007",
    nome_ca: "Hutch 2441S-W21220-0007",
  },
  {
    _id: 2,
    nome_it: "Rock 2317H-W22999-0001",
    nome_en: "Rock 2317H-W22999-0001",
    nome_fr: "Rock 2317H-W22999-0001",
    nome_es: "Rock 2317H-W22999-0001",
    nome_ca: "Rock 2317H-W22999-0001",
  },
  {
    _id: 3,
    nome_it: "Rock 2317H-W22993-0001",
    nome_en: "Rock 2317H-W22993-0001",
    nome_fr: "Rock 2317H-W22993-0001",
    nome_es: "Rock 2317H-W22993-0001",
    nome_ca: "Rock 2317H-W22993-0001",
  },
];
