import React from "react";
import { connect } from "react-redux";
import { setneutra } from "../../state/actions";

const MisuraNeutra = (props) => {
  const { lingua, neutra, setNeutra } = props;
  return (
    <div className="card" style={{ marginTop: "2rem" }}>
      <header className="card-header">
        <p className="card-header-title">
          {lingua.navigazione.misure.misuraNeutraTitolo}
        </p>
      </header>
      <div className="card-content">
        <div className="content">
          {lingua.navigazione.misure.misuraNeutraTesto1}
          <br />
          {lingua.navigazione.misure.misuraNeutraTesto2}
        </div>

        <div className="field">
          <input
            id="idneutra"
            type="checkbox"
            name="idneutra"
            className="switch"
            checked={neutra}
            onChange={() => setNeutra(!neutra)}
          />
          <label htmlFor="idneutra">Camicia di prova</label>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    neutra: state.shop.camicia.misure.neutra,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setNeutra: (val) => dispatch(setneutra(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MisuraNeutra);
