import React from "react";
import { connect } from "react-redux";
import { setbottone, setopenmain } from "../../../state/actions";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { navBottoni } from "../navigazione";

const Bottoni = (props) => {
  const data = useStaticQuery(graphql`
    query MyBottoni {
      allFile(
        sort: { fields: name }
        filter: { absolutePath: { regex: "/bottoni/img/" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const { lingua, bott, newBottone, setOpenMain } = props;

  const setBottone = (tipo) => {
    newBottone(tipo);
    setOpenMain("");
  };

  return (
    <div className="section">
      <div className="row columns is-multiline">
        {navBottoni.map((bottone) => (
          <div className="column is-half" key={bottone._id}>
            <button
              className="buttonLink"
              onClick={() => setBottone(bottone._id)}
            >
              <div className={bott === bottone._id ? "card is-active" : "card"}>
                <div className="card-image">
                  <Img
                    fluid={
                      data.allFile.edges[bottone._id].node.childImageSharp.fluid
                    }
                    alt={bottone[`nome_${lingua.lang}`]}
                  />
                </div>
                <div
                  className={
                    bott === bottone._id
                      ? "card-content has-background-grey-lighter"
                      : "card-content"
                  }
                >
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-7 is-uppercase">
                        {bottone[`nome_${lingua.lang}`]}
                      </p>

                      {/* <p
                        className="subtitle is-7">Info bottoni</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    bott: state.shop.camicia.bottone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newBottone: (val) => dispatch(setbottone(val)),
    setOpenMain: (val) => dispatch(setopenmain(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Bottoni);
