import React, { useState } from "react";
import { euro } from "../helpers";
import { prices, owner } from "../../data";

import {
  navColletti,
  navPolsini,
  navChiusure,
  navTaschini,
  navPinces,
  navBottoni,
  navAsole,
  navContrasti,
} from "../navigazione/navigazione.js";

const BloccoAcquistoMobile = (props) => {
  const [qty, setQty] = useState(1);

  const { lingua, data, camicia } = props;

  const { misure, iniziali } = camicia;

  const infoColletti = navColletti[camicia.colletto][`nome_${lingua.lang}`];
  const infoPolsini = navPolsini[camicia.polsino][`nome_${lingua.lang}`];
  const infoChiusure = navChiusure[camicia.chiusura][`nome_${lingua.lang}`];
  const infoTaschini = navTaschini[camicia.taschino][`nome_${lingua.lang}`];
  const infoPinces = navPinces[camicia.pince][`nome_${lingua.lang}`];
  const infoBottoni = navBottoni[camicia.bottone][`nome_${lingua.lang}`];
  const infoAsole = navAsole[camicia.asola + 1][`nome_${lingua.lang}`];
  const infoClub = camicia.club ? ", Club" : "";
  const infoMask = camicia.mask ? " - " + lingua.navigazione.mascherina : "";
  const infoNeutra = misure.neutra
    ? " (" + lingua.navigazione.misure.misuraNeutra + ")"
    : "";

  let infoContrasti = "";
  let infoContrastoTessuto = "";
  let infoContrastoColletto = "";
  let infoContrastoPolsino = "";
  let infoContrastoChiusura = "";
  if (camicia.contrasto > 0) {
    infoContrasti =
      lingua.navigazione.contrasto + " " + lingua.navigazione.tessuto + " ";
    infoContrastoTessuto =
      navContrasti[camicia.contrasto][`nome_${lingua.lang}`];
    infoContrastoColletto = camicia.contrastoColletto
      ? " " + lingua.navigazione.contrastoColletto
      : "";
    infoContrastoPolsino = camicia.contrastoPolsino
      ? " " + lingua.navigazione.contrastoPolsino
      : "";
    infoContrastoChiusura = camicia.contrastoChiusura
      ? " " + lingua.navigazione.contrastoChiusura
      : "";
  }
  const infoContrastiFinale =
    infoContrasti +
    infoContrastoTessuto +
    infoContrastoColletto +
    infoContrastoPolsino +
    infoContrastoChiusura;
  let infoDivid = infoContrastiFinale !== "" ? ", " : "";
  let infoVela = camicia.colletto < 5 ? camicia.vela + ", " : "";
  let infoDurezza =
    camicia.colletto < 5 ? "Durezza: " + camicia.durezzaColletto + ", " : "";
  const infoCustom =
    lingua.navigazione.colletto +
    ": " +
    infoColletti +
    ", " +
    infoVela +
    infoDurezza +
    lingua.navigazione.polsino +
    ": " +
    infoPolsini +
    ", " +
    lingua.navigazione.chiusura +
    ": " +
    infoChiusure +
    ", " +
    lingua.navigazione.taschino +
    ": " +
    infoTaschini +
    ", " +
    lingua.navigazione.pinces +
    ": " +
    infoPinces +
    ", " +
    lingua.navigazione.bottoni +
    ": " +
    infoBottoni +
    ", " +
    lingua.navigazione.asole +
    ": " +
    infoAsole +
    infoDivid +
    infoContrastiFinale +
    infoClub +
    infoMask;

  let infoMisure = "";
  switch (misure.tipo) {
    case 0:
      infoMisure =
        lingua.navigazione.misure.infoTipoMisure +
        " " +
        lingua.navigazione.misure.infoTipoMisure0 +
        " (" +
        lingua.navigazione.misure.infoTagliaSelezionata +
        " " +
        misure.taglia +
        "). " +
        lingua.navigazione.misure.infoVestibilita +
        ": " +
        misure.fit +
        ".";
      break;
    case 1:
      infoMisure =
        lingua.navigazione.misure.infoTipoMisure +
        " " +
        lingua.navigazione.misure.infoTipoMisure1 +
        ". " +
        lingua.navigazione.misure.Collo +
        ": " +
        misure.collo +
        " cm; " +
        lingua.navigazione.misure.Spalle +
        ": " +
        misure.spalle +
        " cm; " +
        lingua.navigazione.misure.Torace +
        ": " +
        misure.torace +
        " cm; " +
        lingua.navigazione.misure.ColloSpalla +
        ": " +
        misure.colloSpalla +
        " cm; " +
        lingua.navigazione.misure.PettoGiro +
        ": " +
        misure.pettoGiro +
        " cm; " +
        lingua.navigazione.misure.PettoSpalla +
        ": " +
        misure.pettoSpalla +
        " cm; " +
        lingua.navigazione.misure.Girovita +
        ": " +
        misure.girovita +
        " cm; " +
        lingua.navigazione.misure.fianco +
        ": " +
        misure.fianco +
        " cm; " +
        lingua.navigazione.misure.Braccia +
        ": " +
        misure.braccia +
        " cm; " +
        lingua.navigazione.misure.giroManica +
        ": " +
        misure.giroManica +
        " cm; " +
        lingua.navigazione.misure.Polso +
        ": " +
        misure.polso +
        " cm; " +
        lingua.navigazione.misure.Lunghezza +
        ": " +
        misure.lunghezza +
        " cm; " +
        lingua.navigazione.misure.infoVestibilita +
        ": " +
        misure.fit +
        ".";
      break;
    case 2:
      infoMisure =
        lingua.navigazione.misure.infoTipoMisure +
        " " +
        lingua.navigazione.misure.infoTipoMisure2 +
        ". " +
        lingua.navigazione.misure.Braccia +
        ": " +
        misure.braccia +
        " cm; " +
        lingua.navigazione.misure.Collo +
        ": " +
        misure.collo +
        " cm; " +
        lingua.navigazione.misure.Girovita +
        ": " +
        misure.girovita +
        " cm; " +
        lingua.navigazione.misure.Lunghezza +
        ": " +
        misure.lunghezza +
        " cm; " +
        lingua.navigazione.misure.Polso +
        ": " +
        misure.polso +
        " cm; " +
        lingua.navigazione.misure.Spalle +
        ": " +
        misure.spalle +
        " cm; " +
        lingua.navigazione.misure.Torace +
        ": " +
        misure.torace +
        " cm.";
      break;
    case 3:
      infoMisure =
        lingua.navigazione.misure.infoTipoMisure +
        " " +
        lingua.navigazione.misure.infoTipoMisure3 +
        ".";
      break;
    default:
      infoMisure = "";
  }

  let iniColore = lingua.navigazione.iniziali.bianco;
  switch (iniziali.colore) {
    case 0:
      iniColore = lingua.navigazione.iniziali.bianco;
      break;
    case 1:
      iniColore = lingua.navigazione.iniziali.blu;
      break;
    case 2:
      iniColore = lingua.navigazione.iniziali.celeste;
      break;
    case 3:
      iniColore = lingua.navigazione.iniziali.rosso;
      break;
    case 4:
      iniColore = lingua.navigazione.iniziali.nero;
      break;
    case 5:
      iniColore = lingua.navigazione.iniziali.grigio;
      break;
    case 6:
      iniColore = lingua.navigazione.iniziali.arancione;
      break;
    case 7:
      iniColore = lingua.navigazione.iniziali.verde;
      break;
    case 8:
      iniColore = lingua.navigazione.iniziali.rosa;
      break;
    default:
      iniColore = lingua.navigazione.iniziali.bianco;
  }

  let iniPosizione = lingua.navigazione.iniziali.sopra;
  switch (iniziali.posizione) {
    case 0:
      iniPosizione = lingua.navigazione.iniziali.sopra;
      break;
    case 1:
      iniPosizione = lingua.navigazione.iniziali.centro;
      break;
    case 2:
      iniPosizione = lingua.navigazione.iniziali.sotto;
      break;
    default:
      iniPosizione = lingua.navigazione.iniziali.sopra;
  }

  let infoIniziali = "";
  if (iniziali.testo !== "") {
    infoIniziali =
      " --- " +
      lingua.navigazione.iniziali.iniziali +
      ": '" +
      iniziali.testo +
      "', " +
      lingua.navigazione.iniziali.stile1 +
      ": " +
      iniziali.stile +
      ", " +
      lingua.navigazione.iniziali.colore1 +
      ": " +
      iniColore +
      ", " +
      lingua.navigazione.iniziali.posizione1 +
      ": " +
      iniPosizione +
      ".";
  }

  const ordine =
    data.nome +
    " " +
    data.codice +
    " " +
    //data.brand +
    " --- " +
    data[`descrizione_${lingua.lang}`] +
    " --- " +
    infoCustom +
    " --- " +
    infoMisure +
    infoIniziali +
    infoNeutra +
    infoMask;

  let prezzoColletto = 0;
  let prezzoPolsino = 0;
  let prezzoChiusura = 0;
  if (camicia.contrasto > 0) {
    prezzoColletto = camicia.contrastoColletto ? prices.contrastoColletto : 0;
    prezzoPolsino = camicia.contrastoPolsino ? prices.contrastoPolsino : 0;
    prezzoChiusura = camicia.contrastoChiusura ? prices.contrastoChiusura : 0;
  }

  const prezzoClub = camicia.club ? prices.club : 0;
  const prezzoMask = camicia.mask ? prices.mascherina : 0;
  const prezzoIniziali =
    iniziali.testo.length > 0 ? iniziali.testo.length * prices.iniziali : 0;
  const prezzoTotale =
    (data.prezzo +
      prezzoIniziali +
      prezzoColletto +
      prezzoPolsino +
      prezzoChiusura +
      prezzoClub +
      prezzoMask) *
    qty;

  return (
    <div
      className="is-hidden-tablet"
      style={{
        background: "rgba(255,255,255,0.8",
        position: "absolute",
        bottom: 0,
        display: "flex",
        height: "3.25rem",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
      }}
    >
      {misure.neutra ? (
        <p className="is-size-4 has-text-weight-semibold">
          {euro(prezzoTotale)}
        </p>
      ) : (
        <p className="is-size-4 has-text-weight-semibold">
          {euro(prezzoTotale)}
        </p>
      )}

      <div>
        <input
          className="input"
          type="number"
          style={{ maxWidth: "4rem" }}
          value={qty}
          onChange={(e) => setQty(e.target.value)}
          min="1"
          max="10"
        />
      </div>

      <button
        className="button is-dark is-radiusless snipcart-add-item"
        data-item-id={data.codice}
        data-item-price={prezzoTotale}
        data-item-url={owner.appUrl + "/" + data.Path + "/"}
        data-item-description={ordine}
        data-item-image={data.immagine.localFiles[0].childImageSharp.fluid.src}
        data-item-name={lingua.barraAcquisto.camicia + " " + data.nome}
        data-item-weight={450}
        data-item-quantity={qty}
        data-item-max-quantity={10}
        data-item-min-quantity={1}
        data-item-stackable={"never"}
      >
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {lingua.barraAcquisto.carrello}
        </span>
      </button>
    </div>
  );
};

export default BloccoAcquistoMobile;
