import React, { useState } from "react";
import { connect } from "react-redux";
import { presamisure, aggiornamisure, aggiornafit } from "../../state/actions";
import MisuraNeutra from "../misuraneutra";

import Braccia from "../tutorial/braccia";
import Collo from "../tutorial/collo";
import Girovita from "../tutorial/girovita";
import Lunghezza from "../tutorial/lunghezza";
import Polso from "../tutorial/polso";
import Spalle from "../tutorial/spalle";
import Torace from "../tutorial/torace";

import ColloSpalla from "../tutorial/collo-spalla";
import PettoGiro from "../tutorial/petto-giro";
import PettoSpalla from "../tutorial/petto-spalla";
import Fianco from "../tutorial/fianco";
import GiroManica from "../tutorial/giro-manica";

const MisuraSlider = (props) => {
  const {
    lingua,
    tipo,
    fit,
    sBraccia,
    sCollo,
    sGirovita,
    sLunghezza,
    sPolso,
    sSpalle,
    sTorace,
    setMisura,
    aggFit,
    sColloSpalla,
    sPettoGiro,
    sPettoSpalla,
    sFianco,
    sGiroManica,
  } = props;

  const handleInputChange = (event) => {
    setMisura([event.target.name], parseFloat(event.target.value));
  };
  const selectFit = (event) => {
    aggFit(event.target.value);
  };

  let tipoTut = "corpo";
  switch (tipo) {
    case "corpo":
      tipoTut = "corpo";
      break;
    case "camicia":
      tipoTut = "camicia";
      break;
    default:
      tipoTut = "corpo";
      break;
  }

  const [foc, setFoc] = useState(<Collo lingua={lingua} tipoTut={tipoTut} />);
  return (
    <div>
      <div className="columns">
        <div className="column">
          <div style={{ position: "sticky", top: "0.25rem" }}>{foc}</div>
        </div>
        <div className="column">
          <div className="field" style={{ marginBottom: "1.5rem" }}>
            <label className="label">
              {lingua.navigazione.misure.selezionaVestibilita}
            </label>
            <div className="control">
              <div className="select is-primary">
                <select value={fit} onBlur={selectFit} onChange={selectFit}>
                  <option value="Slim">Slim</option>
                  <option value="Regular">Regular</option>
                  <option value="Large">Large</option>
                </select>
              </div>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() => setFoc(<Collo lingua={lingua} tipoTut={tipoTut} />)}
            onTouchStart={() =>
              setFoc(<Collo lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">{lingua.navigazione.misure.Collo}</label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="collo"
                  className="is-fullwidth is-primary"
                  min="35"
                  max="55"
                  step="0.5"
                  value={sCollo}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sCollo + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() => setFoc(<Spalle lingua={lingua} tipoTut={tipoTut} />)}
            onTouchStart={() =>
              setFoc(<Spalle lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Spalle}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="spalle"
                  className="is-fullwidth is-primary"
                  min="38"
                  max="65"
                  step="0.5"
                  value={sSpalle}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sSpalle + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() => setFoc(<Torace lingua={lingua} tipoTut={tipoTut} />)}
            onTouchStart={() =>
              setFoc(<Torace lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Torace}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="torace"
                  className="is-fullwidth is-primary"
                  min="75"
                  max="150"
                  step="0.5"
                  value={sTorace}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sTorace + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<ColloSpalla lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<ColloSpalla lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.ColloSpalla}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="colloSpalla"
                  className="is-fullwidth is-primary"
                  min="12"
                  max="25"
                  step="0.5"
                  value={sColloSpalla}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sColloSpalla + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<PettoGiro lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<PettoGiro lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.PettoGiro}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="pettoGiro"
                  className="is-fullwidth is-primary"
                  min="18"
                  max="30"
                  step="0.5"
                  value={sPettoGiro}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sPettoGiro + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<PettoSpalla lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<PettoSpalla lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.PettoSpalla}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="pettoSpalla"
                  className="is-fullwidth is-primary"
                  min="18"
                  max="30"
                  step="0.5"
                  value={sPettoSpalla}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sPettoSpalla + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<Girovita lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<Girovita lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Girovita}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="girovita"
                  className="is-fullwidth is-primary"
                  min="75"
                  max="150"
                  step="0.5"
                  value={sGirovita}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sGirovita + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() => setFoc(<Fianco lingua={lingua} tipoTut={tipoTut} />)}
            onTouchStart={() =>
              setFoc(<Fianco lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.fianco}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="fianco"
                  className="is-fullwidth is-primary"
                  min="80"
                  max="150"
                  step="0.5"
                  value={sFianco}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sFianco + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<Braccia lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<Braccia lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Braccia}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="braccia"
                  className="is-fullwidth is-primary"
                  min="58"
                  max="78"
                  step="0.5"
                  value={sBraccia}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sBraccia + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<GiroManica lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<GiroManica lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.giroManica}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="giroManica"
                  className="is-fullwidth is-primary"
                  min="35"
                  max="65"
                  step="0.5"
                  value={sGiroManica}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sGiroManica + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() => setFoc(<Polso lingua={lingua} tipoTut={tipoTut} />)}
            onTouchStart={() =>
              setFoc(<Polso lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">{lingua.navigazione.misure.Polso}</label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="polso"
                  className="is-fullwidth is-primary"
                  min="16"
                  max="32"
                  step="0.5"
                  value={sPolso}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sPolso + " cm"}</span>
              </p>
            </div>
          </div>

          <div
            role="button"
            className="field"
            onFocus={() =>
              setFoc(<Lunghezza lingua={lingua} tipoTut={tipoTut} />)
            }
            onTouchStart={() =>
              setFoc(<Lunghezza lingua={lingua} tipoTut={tipoTut} />)
            }
          >
            <div className="label">
              <label className="label">
                {lingua.navigazione.misure.Lunghezza}
              </label>
            </div>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  name="lunghezza"
                  className="is-fullwidth is-primary"
                  min="60"
                  max="100"
                  step="0.5"
                  value={sLunghezza}
                  type="range"
                  onChange={handleInputChange}
                />
              </p>
              <p className="control">
                <span>{sLunghezza + " cm"}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <MisuraNeutra lingua={lingua} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    fit: state.shop.camicia.misure.fit,

    sBraccia: state.shop.camicia.misure.braccia,
    sCollo: state.shop.camicia.misure.collo,
    sGirovita: state.shop.camicia.misure.girovita,
    sLunghezza: state.shop.camicia.misure.lunghezza,
    sPolso: state.shop.camicia.misure.polso,
    sSpalle: state.shop.camicia.misure.spalle,
    sTorace: state.shop.camicia.misure.torace,

    sColloSpalla: state.shop.camicia.misure.colloSpalla,
    sPettoGiro: state.shop.camicia.misure.pettoGiro,
    sPettoSpalla: state.shop.camicia.misure.pettoSpalla,
    sFianco: state.shop.camicia.misure.fianco,
    sGiroManica: state.shop.camicia.misure.giroManica,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMisura: (nam, val) => dispatch(presamisure(nam, val)),
    aggMisure: (val) => dispatch(aggiornamisure(val)),
    aggFit: (val) => dispatch(aggiornafit(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MisuraSlider);
